import { BsFacebook, BsLinkedin, BsTwitch, BsTwitterX, BsYoutube } from "react-icons/bs";
import "./Navbar.css";
import { BiSolidDownArrow } from "react-icons/bi";
import { VscThreeBars } from "react-icons/vsc";

const Navbar = ({logo, navitems}) => {

    return (
        <nav>
            <a href="#"><img className="logo" src={logo} /></a>
            <div className="items">
                <ul>
                    {navitems.map((element, index) => {
                        return (<span key={index}><li>{element}</li><BiSolidDownArrow /></span>)
                    })}
                </ul>
            </div>
            <div className="items">
                <ul>
                    <a href="#"><BsFacebook /></a>
                    <a href="#"><BsTwitterX /></a>
                    <a href="#"><BsYoutube /></a>
                    <a href="#"><BsLinkedin /></a>
                    <a href="#"><BsTwitch /></a>
                    <button><VscThreeBars />Login</button>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
