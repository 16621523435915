import React from 'react';
import './SecondSection.css';
import { BiCheck } from 'react-icons/bi';

const SecondSection = ({perksItems}) => {
    return (
        <div className='second'>
            <h2>Upgrade Your Experience With Premium Perks</h2>
            <span>From personal blogs to high-traffic e-commerce sites, we have got a plan that fits</span>
            <div className='btn-area'>
                <a href='#'>Annualy</a>
                <a href='#'>Monthly</a>
            </div>
            <div className='perks-container'>
                {perksItems.map((element, index) => {
                    return (
                        <div key={index} className='perks'>
                            <h3>{element.title}</h3>
                            <p>{element.desc}</p>
                            <p><span className='price'>{element.price}</span> / ANNUALY</p>
                            {element.perks.map((item, i) => { return (
                                <div key={i} className='check-perks'>
                                    <BiCheck />
                                    <p>{item}</p>
                                </div>
                            )})}
                            {
                                !element.disableBtn
                                ? <button disabled={element.disableBtn} className='enabled-btn'>{element.btnText}</button>
                                : <button disabled={element.disableBtn} className='disabled-btn'>{element.btnText}</button>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default SecondSection;
