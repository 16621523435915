export const footeritems = [
    {
        title: 'Web Hosting',
        items: ["cPanel Hosting", "Plesk Hosting", "WordPress Hosting", "Elite Web Hosting"]
    },
    {
        title: 'VPS Hosting',
        items: ["VPS Hosting", "VPS Storage"]
    },
    {
        title: 'Domain & Security',
        items: ["Register Domain", "Transfer Domain", "Domains Pricing", "Security & SSL", "DNS Checker", "Whois Lookup"]
    },
    {
        title: 'Services',
        items: ["iStorage", "OX App Suite", "Email Security", "Ispmanager"]
    },
    {
        title: 'Support',
        items: ["Report Abuse", "Illevante Cloud Forum", "Support Form"]
    },
    {
        title: 'Resources',
        items: ["Documentation", "Technology Partners", "Newsroom", "Newsletter", "Download Center", "System Status"]
    },
    {
        title: 'Company',
        items: ["Our Story", "Datacenters", "Sustainability", "Careers", "Legal Hub", "Contact Us"]
    },
]