import './App.css';
import FirstSection from './components/FirstSection/FirstSection';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import SecondSection from './components/SecondSection/SecondSection';
import ThirdSection from './components/ThirdSection/ThirdSection';
import { appsImages } from './components/ThirdSection/appsImages';
import { perksItems } from './components/SecondSection/perksItems';
import { footeritems } from './components/Footer/footerItems';

const footerImage = 'https://ic33.b-cdn.net/wp-content/uploads/2023/03/green_footer.webp';
const copyImage = 'https://ic33.b-cdn.net/wp-content/uploads/2023/08/fav_logo_l.png';
const logo = "https://ic33.b-cdn.net/wp-content/uploads/2023/08/illevante_cloud_logo_l.png";
const navitems = ["Web Hosting", "VPS Hosting", "Domains & Security", "Services", "Company", "Resources"];

function App() {

  return (
    <div className='App'>
      <Navbar logo={logo} navitems={navitems} />
      <Hero />
      <FirstSection />
      <SecondSection perksItems={perksItems}/>
      <ThirdSection appsImages={appsImages}/>
      <Footer footerImage={footerImage} copyImage={copyImage} footeritems={footeritems}/>
    </div>
  );
}

export default App;
