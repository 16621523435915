import React from 'react';
import './FirstSection.css';

const FirstSection = () => {
    return (
        <section className='flex-container'>
            <div className='grid-container'>
                <h1 className='item1'>WordPress Hosting Like Never Before</h1>
                <div className='item2'>
                    <h2>Easy To Use</h2>
                    <p>Illevante Cloud's platform is easy to use, even for beginners. You can get started with just a few clicks.</p>
                </div>
                <div className='item3'>
                    <h2>SSD Storage</h2>
                    <p>SSD storage, which is faster than traditional hard drives. This means your website will load faster and perform better.</p>
                </div>
                <div className='item4'>
                    <h2>Unmetered Bandwidth</h2>
                    <p>We offer unmetered bandwidth, which means you can have as much traffic as you need without having to worry about overage charges.</p>
                </div>
                <div className='item5'>
                    <p>Illevante Cloud offers a comprehensive set of features that make it a good choice for WordPress hosting. 
                        If you are looking for a managed WordPress hosting provider that offers security, performance, and ease of use, Illevante Cloud is a good option to consider.
                    </p>
                    <a href='#'>Have a question?</a>
                </div>
                <div className='item6'>
                </div>
            </div>
        </section>
    );
};

export default FirstSection;
