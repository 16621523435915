export const appsImages = [
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/almalinux.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/ubuntu.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/astra.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/centos.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/debian.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/freebsd.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/rocky_linux.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/redos.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/suse.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/oracle.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/vzlinux.webp",
    "https://ic33.b-cdn.net/wp-content/uploads/2023/08/windows.webp",
]