import React from 'react';
import './Footer.css';
import { FaEthernet } from 'react-icons/fa';
import { BsFacebook, BsGithub, BsLinkedin, BsStripe, BsTwitter, BsYoutube } from 'react-icons/bs';

const Footer = ({footerImage, copyImage, footeritems}) => {
    return (
        <footer>
            <div className='footer-container'>
            {footeritems.map((element, index) => {
                return (
                    <div key={index}>
                        <h4>{element.title}</h4>
                        <div className='footer-items'>{element.items.map((item, i) => {
                            return (
                                <a href='#' key={i}>{item}</a>
                            )
                        })}
                        </div>
                    </div>
                )
            })}
            </div>
            <div className='lang-container'>
                <div className='lang'>
                    <FaEthernet />
                    <p>English</p>
                    <p>Español</p>
                    <p>Français</p>
                    <p>Deutsch</p>
                    <p>Русский</p>
                    <p>Türkçe</p>
                    <p>العربية</p>
                </div>
                <p>Cookie Preferences</p>
            </div>
            <p className='copy-text'>&copy; 2024 Illevante Cloud Inc., All Rights Reserved.</p>
            <div className='end-container'>
                
                <p>
                    Our services include web hosting, cloud hosting and virtual private server (VPS) hosting. The company also offers add-ons such as domain name registration, email hosting, website builders, and security tools. We are working hard to provide the best technical infrastructure and maintain the servers, ensuring their uptime, and providing client support.
                </p>
                <img src={footerImage} />
            </div>
            <div className='end-container'>
                <div className='lang lang-text'>
                    <img src={copyImage} />
                    <p> | Follow Us</p>
                    <div className='media-icons'>
                        <a href='#'><BsFacebook /></a>
                        <a href='#'><BsTwitter /></a>
                        <a href='#'><BsLinkedin /></a>
                        <a href='#'><BsYoutube /></a>
                        <a href='#'><BsGithub /></a>
                    </div>
                </div>
                <div className='lang'>
                    <BsStripe />
                    <BsStripe />
                    <BsStripe />
                    <BsStripe />
                    <BsStripe />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
