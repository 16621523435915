export const perksItems = [
    {
        title: "Scorpii",
        desc: "For start-up businesses and small projects",
        price: "$40",
        btnText: "NOT AVAILABLE",
        disableBtn: true,
        perks: ["1 Website", "20 GB NVMe Storage", "Unlimited Bandwidth", "Free Domain",
            "Free Website Migration", "10 Days Backups", "Free SSL Certificate"]
    },
    {
        title: "Cygni",
        desc: "For start-up businesses and small projects",
        price: "$75",
        btnText: "Order Now",
        disableBtn: false,
        perks: ["1 Website", "20 GB NVMe Storage", "Unlimited Bandwidth", "Free Domain",
            "Free Website Migration", "10 Days Backups", "Free SSL Certificate"]
    },
    {
        title: "Scuti",
        desc: "For start-up businesses and small projects",
        price: "$130",
        btnText: "Order Now",
        disableBtn: false,
        perks: ["1 Website", "20 GB NVMe Storage", "Unlimited Bandwidth", "Free Domain",
            "Free Website Migration", "10 Days Backups", "Free SSL Certificate"]
    },
]