import React from 'react';
import './ThirdSection.css';

const ThirdSection = ({appsImages}) => {
    return (
        <div className='third'>
            <h2>Upgrade Your Experience With Premium Perks</h2>
            <span>Choose the ideal operating system for your virtual private server. Click and go!</span>
            <div className='btn-area'>
                <a href='#'>OS</a>
                <a href='#'>Panels & Apps</a>
            </div>
            <div className='apps-container'>
                {
                    appsImages.map((item, index) => {
                        return (
                            <div key={index} className='img-card'>
                                <img src={item} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default ThirdSection;
