import React from 'react';
import "./Hero.css";

const Hero = () => {
    return (
        <section className='hero'>
            <img src='https://ic33.b-cdn.net/wp-content/uploads/2023/03/wordPress_logo.png' />
            <h1>Innovate And Create With WordPress</h1>
            <p>Step into a world of unparalleled WordPress hosting with our meticulously designed masterpiece hosting plans</p>
            <a href='#'>Explore Plans</a>
            <div>
                <p>Illevante Cloud / WordPress Hosting</p>
            </div>
        </section>
    );
};

export default Hero;
